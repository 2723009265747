import { ConfigProvider, Flex, Layout, Space, theme } from "antd";
import React from "react";
import Pages from "./pages";

function App() {
	return (
		<ConfigProvider
			theme={{
				algorithm: theme.darkAlgorithm,
				token: {
					colorPrimary: "#f57926",
				},
			}}
		>
			<Layout
				style={{
					minHeight: "100vh",
					padding: 24,
				}}
			>
				<Space direction="vertical">
					<Flex justify="center" align="center">
						<img
							src="/logo.svg"
							style={{
								height: 50,
							}}
							alt="logo"
						/>
					</Flex>
					<Layout.Content>
						<Pages />
					</Layout.Content>
				</Space>
			</Layout>
		</ConfigProvider>
	);
}

export default App;
