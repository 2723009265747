import { Tabs } from "antd";
import React from "react";
import NameToEmail from "./name-to-email";
import UpworkPrompt from "./upwork-prompt";

function Pages() {
	return (
		<>
			<Tabs>
				<Tabs.TabPane tab="Upwork Prompt" key="2">
					<UpworkPrompt />
				</Tabs.TabPane>
				<Tabs.TabPane tab="Name to Email" key="1">
					<NameToEmail />
				</Tabs.TabPane>
			</Tabs>
		</>
	);
}

export default Pages;
