import Step from "./components/Step";
import StepOne from "./components/StepOne";
import StepThree from "./components/StepThree";

function App() {
	return (
		<div>
			<Step
				heading={"Step 1"}
				description={`Click below and select one or multiple csv files downloaded from "evabot"`}
			>
				<StepOne />
			</Step>
			<Step
				heading={"Step 2"}
				description={
					<ol>
						<li>
							Upload the downloaded file from 1st step to google sheets. "Open"{" "}
							{">"} "Upload" {">"} "Select file"
						</li>
						<li>
							Select the columns "Permutation 1" to "Permutation 18". (AK to BB)
						</li>
						<li>
							Click "Insert" {">"} "Smart Chip" {">"} "Convert to people chip"
						</li>
						<li>Wait until a pop up appears on bottom right</li>
						<li>
							Select column "Permutation 1 Compare" till "Permutation 18
							Compare". (BU to CL)
						</li>
						<li>Now Copy with "Edit" {">"} "Copy"</li>
						<li>
							Select the first "Permutation 1 Compare" column and paste via{" "}
							<b>
								"Edit" {">"} "Paste Special" {">"} "Values only"
							</b>{" "}
						</li>
						<li>
							Download the file from "File" {">"} "Download" {">"} ".csv"
						</li>
					</ol>
				}
			/>
			<Step
				heading={"Step 3"}
				description={`Click below and select the file downloaded in 2nd step`}
			>
				<StepThree />
			</Step>
		</div>
	);
}

export default App;
