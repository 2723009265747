export const SECTIONS = [
	{
		name: "job-information",
		title: "Job Information",
		placeholder: "Enter upwork job information here",
		rows: 15,
	},
	{
		name: "job-related-questions",
		title: "Job Related Questions",
		placeholder: "Enter upwork job related questions here",
		rows: 15,
	},
	{
		name: "cover-letter-template",
		title: "Cover Letter Template",
		placeholder: "Enter upwork cover letter template here",
		rows: 15,
	},
	{
		name: "basic-info",
		title: "Basic Information",
		placeholder:
			"Enter your basic information here like years of experience, skills, etc.",
		rows: 15,
	},
	{
		name: "tech-stacks",
		title: "Tech Stacks",
		placeholder: "Enter your tech stacks here like React, Node, etc.",
		rows: 15,
	},
	{
		name: "previous-work",
		title: "Previous Work",
		placeholder:
			"Enter your previous work here like projects, experiences, etc.",
		rows: 15,
	},
	{
		name: "prompt-instructions",
		title: "Prompt Instructions",
		placeholder: "Enter any additional instructions here",
		rows: 15,
	},
];
