import { EyeOutlined } from "@ant-design/icons";
import { Button, Card, FloatButton, message, Modal, Tooltip } from "antd";
import { useState } from "react";

const getValues = (key) => {
	const value = localStorage.getItem(key);
	if (!value) return "";
	return JSON.parse(value).trim();
};

const sectionInstructions = {
	"job-information":
		"Following is the job information that you will write a cover letter for:",
	"cover-letter-template":
		"Following is the cover letter template that you can use to write a cover letter.",
	"basic-info":
		"Following is information about you that you can use to write a cover letter:",
	"tech-stacks": "Following are the tech stacks that you are familiar with:",
	"previous-work": "Following are some of your previous work:",
	"prompt-instructions":
		"Following are instructions that you will follow strictly to write a cover letter:",
	"job-related-questions":
		"Following are the job related questions that you will answer after the cover letter according to your experience and projects:",
};

const startPrompt =
	"You are a senior software engineer on Upwork with an Intermediate English level.";

export const generatePrompt = () => {
	const sectionsKeysSorted = [
		"prompt-instructions",
		"basic-info",
		"tech-stacks",
		"previous-work",
		"cover-letter-template",
		"job-information",
		"job-related-questions",
	];

	const prompt = sectionsKeysSorted
		.filter((key) => getValues(key))
		.map((key) => `${sectionInstructions[key]}\n\n${getValues(key)}`)
		.join("\n\n-----------\n\n");

	return `${startPrompt}\n\n${prompt}`;
};

const PreviewButton = () => {
	const [open, setOpen] = useState(false);
	const [preview, setPreview] = useState("");
	const [messageApi, contextHolder] = message.useMessage();

	return (
		<>
			{contextHolder}
			<Tooltip title="Preview" placement="left">
				<FloatButton
					type="primary"
					icon={<EyeOutlined />}
					onClick={() => {
						setPreview(generatePrompt());
						setOpen(true);
					}}
				/>
			</Tooltip>
			<Modal
				open={open}
				onCancel={() => setOpen(false)}
				centered
				title="Prompt Preview"
				width="calc(100vw - 100px)"
				style={{
					width: "1000px",
				}}
				footer={[
					<Button
						type="primary"
						onClick={() => {
							navigator.clipboard.writeText(preview);
							messageApi.success("Copied to clipboard");
						}}
					>
						Copy to Clipboard
					</Button>,
				]}
			>
				<div
					style={{
						whiteSpace: "pre-wrap",
						maxHeight: "700px",
						overflow: "auto",
					}}
				>
					<Card>{preview}</Card>
				</div>
			</Modal>
		</>
	);
};

export default PreviewButton;
