import {
	ClearOutlined,
	CopyOutlined,
	DownloadOutlined,
	UploadOutlined,
} from "@ant-design/icons";
import { useLocalStorage } from "@uidotdev/usehooks";
import {
	Button,
	Col,
	Flex,
	FloatButton,
	Input,
	message,
	Row,
	Space,
	Tooltip,
	Typography,
	Upload,
} from "antd";
import PreviewButton, { generatePrompt } from "./PreviewButton";
import { SECTIONS } from "./constants";

const Section = ({
	name,
	title,
	rows = 6,
	placeholder = "Enter your prompt here",
}) => {
	const [value, saveValue] = useLocalStorage(name, null);
	return (
		<Space
			direction="vertical"
			style={{
				width: "100%",
			}}
			size="small"
		>
			<Flex justify="space-between">
				<Typography.Text strong>{title}</Typography.Text>
			</Flex>
			<Input.TextArea
				placeholder={placeholder}
				value={value}
				onChange={(e) => saveValue(e.target.value)}
				rows={rows}
			/>
		</Space>
	);
};

const UpworkPrompt = () => {
	const [messageApi, contextHolder] = message.useMessage();

	const readJsonFromFile = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = (event) => {
				try {
					const jsonData = JSON.parse(event.target.result);
					resolve(jsonData);
				} catch (error) {
					reject(error);
				}
			};
			reader.onerror = (error) => {
				reject(error);
			};
			reader.readAsText(file);
		});
	};

	const onUpload = async (file) => {
		const jsonData = await readJsonFromFile(file);
		console.log(jsonData);
		try {
			let sections = SECTIONS.map((section) => {
				const sample =
					jsonData.find((s) => s.name === section.name)?.sample || "";
				return { ...section, sample };
			});
			console.log(JSON.stringify(sections));
			sections.forEach((section) => {
				localStorage.setItem(section.name, JSON.stringify(section.sample));
			});
			window.location.reload();
		} catch (error) {
			console.log(error);
		}
		return false;
	};

	const onDownload = () => {
		const jsonData = SECTIONS.map((section) => ({
			name: section.name,
			sample: JSON.parse(localStorage.getItem(section.name)),
		}));

		const blob = new Blob([JSON.stringify(jsonData, null, 2)], {
			type: "application/json",
		});
		const url = URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.href = url;
		a.download = "my-cv.json";
		a.click();
	};

	return (
		<Space direction="vertical" style={{ width: "100%" }} size="large">
			{contextHolder}
			<Flex justify="end" gap={10}>
				<Upload multiple={false} accept=".json" beforeUpload={onUpload}>
					<Button icon={<UploadOutlined />} type="primary" ghost size="large">
						Import
					</Button>
				</Upload>
				<Button
					icon={<DownloadOutlined />}
					type="primary"
					ghost
					onClick={onDownload}
					size="large"
				>
					Export
				</Button>
			</Flex>
			<Row gutter={[16, 16]}>
				{SECTIONS.map((section) => (
					<Col span={8}>
						<Section key={section.name} {...section} />
					</Col>
				))}
			</Row>
			<FloatButton.Group shape="square">
				<Tooltip title="Copy to Clipboard" placement="left">
					<FloatButton
						type="primary"
						onClick={() => {
							navigator.clipboard.writeText(generatePrompt());
							messageApi.success("Copied to clipboard");
						}}
						icon={<CopyOutlined />}
					/>
				</Tooltip>
				<Tooltip title="Clear all sections" placement="left">
					<FloatButton
						type="default"
						icon={<ClearOutlined />}
						onClick={() => {
							SECTIONS.forEach((section) => {
								localStorage.removeItem(section.name);
							});
							window.location.reload();
						}}
					/>
				</Tooltip>
				<PreviewButton />
			</FloatButton.Group>
		</Space>
	);
};

export default UpworkPrompt;
